.required-asterisk {
  color: red;
}

.sm-navigation {
  --tsc-nav-link-border-color: #adb5bd;

  hr {
    display: none !important;
  }

  .nav-link {

    &:first-child {
      border-top: 1px solid var(--tsc-nav-link-border-color);
    }

    --bs-nav-link-padding-y: 1.25rem !important;
    border-bottom: 1px solid var(--tsc-nav-link-border-color);
  }

  .HeaderActionLink {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
    border-bottom: 1px solid var(--tsc-nav-link-border-color);
  }

  .HeaderSmallActionLink {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;

    & + .HeaderSmallActionLink{
      border-top: 1px solid var(--tsc-nav-link-border-color);
      padding-bottom: 0 !important;

    }
  }
}

.h-custom {
  min-height: calc(100% - 73px);
}

@media (max-width: 768px) {
  .h-custom {
    min-height: calc(100% - 73px);
  }
}

.bg-main-body {
  background-color: rgb(248, 249, 250);
}

.sub-body-container {
  margin-bottom: 25px;
  padding: 20px 10px;
  background-color: #FFF;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}